const actions = {
  INCREASE: "INCREASE",
  DECREASE: "DECREASE",
  ADD: "ADD",
  REMOVE: "REMOVE",
  CLEAR: "CLEAR",
  CHECKOUT: "CHECKOUT",
  SET_LOCATION: "SET_LOCATION",
  SET_CURRENCY: "SET_CURRENCY",
  RESET_DELIVERY_AMOUNT: "RESET_DELIVERY_AMOUNT"
};

export default actions;
