import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
const Home = lazy(() => import("pages/Home"));
const Menu = lazy(() => import("pages/Menu"));
const Contact = lazy(() => import("pages/Contact"));
const Success = lazy(() => import("pages/Success"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/menu",
    exact: true,
    component: Menu
  },
  {
    path: "/contact",
    exact: true,
    component: Contact
  },
  {
    path: "/order-successful",
    exact: true,
    component: Success
  },
  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/" />
  }
];

export default routes;
